<template>
  <div>
    <b-sidebar
      id="sidebar-right-site-ownership"
      ref="sidebar_site_ownership"
      :visible="showOpen"
      bg-variant="white"
      style="z-index: 9999;"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open', val)"
    >
      <template #default="{ hide }">
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50"
          style="background-color: #F0F1F7; height: 91px;"
        >
          <b-card
            no-body
            class="overflow-hidden w-100"
            style="background-color: #F0F1F7;"
          >
            <header-sidebar :idsite-header.sync="idsite" />
          </b-card>
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="20"
            @click="hide"
          />
        </div>
        <div class="b-sidebar-body" style="max-height: calc(100vh - 155px); overflow-y: auto;">
          <!-- {{idsite}} -->
          <div class="ml-3 my-2">
            <p
              v-if="idsite.label"
              class="mb-0"
              style="font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 14px;"
            >
              Project
            </p>
            <h4
              v-if="idsite.label"
              class=""
              style="font-weight: 700;"
            >
              {{ idsite.label }}
            </h4>
          </div>
          <hr v-if="idsite.label">
          <h3
            class="mb-0 pb-0 pt-2"
            style="font-weight: 700; color: #181E38 !important; padding: 40px;"
          >
            {{typeOwnership === true || typeOwnership === 'true' ? 'Claim site' : typeOwnership === false ? 'Site disconnected' : 'Claim site'}}
          </h3>
          <claim-ownership
            style="padding: 40px;"
            class="mt-0 pt-0 pb-0 container-body-config-claim"
            :name="idsite.name"
            :url.sync="idsite.domain"
            :key-site.sync="keyID"
            :img="idsite.imgSite"
            :siteProp="idsite"
            :claim-license.sync="claimLicense"
            :show-open.sync="showOpen"
            :type-ownership.sync="typeOwnership"
          />
          
          <b-button
            style="background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 15px; margin-right: 40px;"
            class="float-right"
            variant="primary"
            @click.prevent="submitEditar"
            :disabled="itemVerify === 'active' ? false : true"
            v-if="typeOwnership === false"
          >
            <span
              class=""
              style="font-weight: 700; color: #FFFFFF;"
            >{{typeOwnership === true || typeOwnership === 'true' ? 'Claim site' : typeOwnership === false ? 'Claim site' : 'Claim site'}}</span>
          </b-button>
        </div>
        <help-footer :url="idsite.domain" />
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BLink,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, minLength, password, confirmed, email,
} from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
import ClaimOwnership from '@/views/apps/sites/sites-add/ClaimOwnership.vue'
import HelpFooter from '@/views/components/footer/HelpFooter'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import Urlbox from 'urlbox'
import axios from '@axios'
import { db, auth, storage, dbErrors, firebase } from '@/firebase'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    BLink,
    ClaimOwnership,
    HelpFooter,
    HeaderSidebar,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpen',
    event: 'update:show-open',
  },
  props: ['idsite', 'showOpen', 'typeOwnership'],
  data() {
    return {
      email,
      required,
      password,
      confirmed,

      agentesAsignados: '',
      userData: '',
      password: '',
      passwordConfirm: '',
      emailOld: '',
      showDateHour: false,
      selectedPermissions: [],
      imgSite: '',
      keyID: '',
      claimLicense: false,
      itemVerify: localStorage.getItem('verify'),
      events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
    }
  },
  watch: {
    // idsite(val) {
      // console.log(val)
    //   if(val) {
    //     this.userData = {}
    //     const ref = db.collection('Users').doc(val)
    //     ref.onSnapshot(doc => {
    //       this.usuarios = {}
    //       this.usuarios = {
    //           id: doc.data().uid,
    //           fullName: doc.data().nombre,
    //           firstName: doc.data().firstName ? doc.data().firstName : '',
    //           lastName: doc.data().lastName ? doc.data().lastName : '',
    //           email: doc.data().email,
    //           role: doc.data().role,
    //           avatar: doc.data().foto,
    //           contact: doc.data().contact ? doc.data().contact : '',
    //           permissions: doc.data().permissions ?  doc.data().permissions : [],
    //       }
    //       this.emailOld = doc.data().email
    //       this.userData = this.usuarios
    //     })
    //   }
    // },
    showOpen(val) {
      localStorage.removeItem('verify')
      if(val) {
        localStorage.setItem('openClaim', true)
        document.body.style.overflow = 'hidden'

        const user = auth.currentUser
        if(user) {
          this.createScript()

          db.collection('Users').doc(user.uid).get()
          .then(userDoc => {
            const getFirstUser = userDoc.id.slice(0, 4);
            const getFirstSite = this.$route.params.id.slice(0, 4);
            // const getFirstProject = this.$route.params.project.slice(0, 3);
            this.keyID = getFirstUser + getFirstSite
          })
        }

        var getClaimLicense = localStorage.getItem('configRunLicense')
        if(getClaimLicense) {
          this.claimLicense = true
        }
        // console.log(this.idsite.projectid)

        // setTimeout(() => {
        //   if(this.idsite.id) {
        //     db.collection('Sites').doc(this.idsite.id).get()
        //     .then(siteDoc => {
        //       if(siteDoc.exists) {
        //         // console.log(siteDoc.data().homepage)
        //         // Plugin your API key and secret
        //         const urlbox = Urlbox('iWVAo1U5wUbLhzWJ', 'c3e32765daba406cb27d1ad22ed93b6d');
                
        //         // Set your options
        //         const options = {
        //           url: siteDoc.data().homepage,
        //           // thumb_width: 1440,
        //           width: 1440,
        //           height: 840,
        //           format: "jpg",
        //           quality: 70,
        //           delay: 500
        //         };
                
        //         const urlImgUrl = urlbox.buildUrl(options)

        //         var obj = {
        //           url: urlImgUrl
        //         }
        //         console.log(urlImgUrl)
        //         var config = {
        //           method: 'POST',
        //           url: 'https://luguzxy83e.execute-api.us-east-2.amazonaws.com/prod/imgseocloud',
        //           headers: {
        //             'Content-Type': 'application/json',
        //           },
        //           data: JSON.stringify(obj),
        //         }

        //         this.axios(config)
        //         .then(async response => {
        //           // console.log(response.data)
        //           const refImg = await storage.ref().child('sitesImgs').child(`${Date.now()}siteImg`)
        //           const upl = await refImg.putString(response.data, 'data_url')
        //           const urlDescarga = await refImg.getDownloadURL()
        //           // this.imgSite = urlDescarga
        //           // console.log(urlDescarga)

        //           // update thumbnail
        //           db.collection('Sites').doc(siteDoc.id).update({
        //             thumbnail: urlDescarga
        //           })

        //           db.collection('Sites_cache').doc(siteDoc.id).update({
        //             thumbnail: urlDescarga
        //           })

        //           db.collection('Projects').doc(this.idsite.projectid).collection('sites').doc(siteDoc.id).update({
        //             thumbnail: urlDescarga
        //           })

        //           this.idsite.imgSite = urlDescarga
        //         })
        //         .catch(error => {
        //           console.log(error)
        //         })
        //       }
        //     })
        //   }
        // }, 1500)
      } else {
        localStorage.removeItem('openClaim')
        localStorage.removeItem('configRunLicense')
        this.claimLicense = false
        document.body.style.overflow = 'auto'
      }
    }
  },
  validations: {
  },
  methods: {
    createScript() {
      const user = auth.currentUser
      if(user) {
        db.collection('Users').doc(user.uid).get()
        .then(userDoc => {
          if(userDoc.exists) {
            db.collection('Sites').doc(this.$route.params.id).get()
            .then(siteDoc => {
              if(siteDoc.exists) {
                var owners = siteDoc.data().owners && siteDoc.data().owners.length ? siteDoc.data().owners : []

                if(!owners.includes(userDoc.id)) {
                  var siteSelected = siteDoc.data().homepage
                  var urlTransform = new URL(siteSelected)
                  var domainConverter = urlTransform.protocol + '//' + urlTransform.host

                  var getFirstUser = userDoc.id.slice(0, 4);
                  var getFirstSite = this.$route.params.id.slice(0, 4);
                  var keyID = getFirstUser + getFirstSite

                  var obj = {}
                  obj.author = userDoc.data().email
                  obj.css = ""
                  obj.domain = domainConverter
                  obj.key = keyID
                  obj.mode = 1

                  // var proPages = siteDoc.data().proPages && siteDoc.data().proPages.length ? siteDoc.data().proPages : []
                  var proPages = [siteDoc.data().homepage]
                  var pagesAlreadyProcessed = []
                  proPages.forEach(ele => {
                    if(pagesAlreadyProcessed.filter(item => item === ele).length === 0) {
                      var urlTransform = new URL(ele)

                      pagesAlreadyProcessed.push(ele)
                      var pathURLConverter = urlTransform.pathname === '/' && !ele.endsWith('/') ? ele + '/' : ele
                      var encodedURL = btoa(pathURLConverter)
                      obj[encodedURL] = {
                        "title": "",
                        "path": urlTransform.pathname,
                        "url": ele,
                        "description": "",
                        "canonical": "",
                        "siteName": "",
                        "alt": [],
                        "src": [],
                        "dlinks": [],
                        "gp": [],
                        "tags": {},
                        "tgstxt": {},
                        "findAndReplace": [
                            {
                            "old": "seocloudtextfixer",
                            "new": "SEOCloud text fixer"
                            }
                        ]
                      }
                    }
                  })

                  var config = {
                    method: 'POST',
                    url: 'https://apijs-wwkeahz7bq-uc.a.run.app',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    data: JSON.stringify(obj),
                  }

                  this.axios(config)
                  .then(response => {
                    console.log('script created: '+response.data.status)
                  })
                  .catch(e => console.log(e.message))

                  var objWP = {
                    changes: [],
                    redirects: []
                  }

                  Object.entries(obj).forEach(([key, value]) => {
                    // console.log(`Clave: ${key}, Valor:`, value.a);
                    if(key !== 'author' && key !== 'css' && key !== 'domain' && key !== 'key' && key !== 'mode') {
                      var objErrPageWP = {}
                      objErrPageWP.url = value.url
                      objErrPageWP.description = value.description
                      objErrPageWP.canonical = value.canonical
                      objErrPageWP.title = value.title
                      objErrPageWP.search_and_replace = []
                      objErrPageWP.images_src_replace = []
                      objErrPageWP.images_alt_replace = []
                      objErrPageWP.links_replace = []

                      if(value.tgstxt.h1 && value.tgstxt.h1.length) {
                          value.tgstxt.h1.forEach(ele => {
                          objErrPageWP.search_and_replace.push({
                              'tag': 'h1',
                              'target_text': ele.txt,
                              'replace_text': ele.ntx
                          })
                          })
                      }

                      if(value.tgstxt.h2 && value.tgstxt.h2.length) {
                          value.tgstxt.h2.forEach(ele => {
                              objErrPageWP.search_and_replace.push({
                                  'tag': 'h2',
                                  'target_text': ele.txt,
                                  'replace_text': ele.ntx
                              })
                          })
                      }

                      if(value.src && value.src.length) {
                          value.src.forEach(ele => {
                              objErrPageWP.images_src_replace.push({
                                  'target_src': ele.url,
                                  'replace_src': ele.src
                              })
                          })
                      }

                      if(value.alt && value.alt.length) {
                          value.alt.forEach(ele => {
                              objErrPageWP.images_alt_replace.push({
                                  'target_src': ele.url,
                                  'replace_alt': ele.alt
                              })
                          })
                      }

                      if(value.dlinks && value.dlinks.length) {
                          value.dlinks.forEach(ele => {
                              objErrPageWP.links_replace.push({
                                  'target_url': ele.url,
                                  'replace_url': ele.rmp
                              })
                          })
                      }

                      if(value.findAndReplace && value.findAndReplace.length) {
                          value.findAndReplace.forEach(ele => {
                              objErrPageWP.search_and_replace.push({
                                  'tag': 'p',
                                  'target_text': ele.old,
                                  'replace_text': ele.new
                              })
                          })
                      }
                      objWP.changes.push(objErrPageWP)
                    }
                  });
                  var configWP = {
                    method: 'POST',
                    // url: `${domainConverter}/wp-admin/admin-ajax.php?action=seocloud_autosync_changes`,
                    url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/writeCrawl/json-wp',
                    headers: {
                      'Content-Type': 'application/json',
                      // 'User-Agent': 'insomnia/10.1.1',
                      // 'seocloud-registration-key': keyID
                    },
                    data: JSON.stringify({data: objWP, key: keyID, domain: domainConverter}),
                  }

                  this.axios(configWP)
                  .then(() => {
                    console.log('fixes file updated wp')
                  })
                  .catch(e => console.log(e.message))
                }
              }
            })
          }
        })
      }
    },
    verifyFunction() {
      var obj = {
        "url": this.idsite.domain,
        "scriptSrc": `https://cdn.seocloud.ai/${this.keyID}`
      }

      var config = {
        method: 'POST',
        url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/check-script',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(obj),
      }

      this.axios(config)
      .then(response => {
        console.log(response.data)
        if(!response.data.scriptPresent) {
          // this.counterTriesVerify >= 3 ? this.counterTriesVerify = 1 : this.counterTriesVerify += 1
          // this.verifying = false
          // this.processVerificationFail = true
        } else {
          // this.processVerificationFail = false
          // this.counterTriesVerify = 0
          console.log('exist file in html')
          setTimeout(() => {
            // this.verifying = false
            // this.processVerificationFail = true
            // this.processVerificationSuccess = true
            localStorage.setItem('verify', 'active')

            const user = auth.currentUser
            if(user) {
              db.collection('Users').doc(user.uid).get()
              .then(userDoc => {
                var userData = {}
                userData.uid = userDoc.id
                userData.foto = userDoc.data().foto ? userDoc.data().foto : ''
                userData.name = userDoc.data().nombre ? userDoc.data().nombre : ''
                userData.status = userDoc.data().status ? userDoc.data().status : ''
                userData.email = userDoc.data().email ? userDoc.data().email : ''
                db.collection('Projects').doc(this.idsite.projectid).collection('sites').doc(this.idsite.key).update({
                  owner: userData,
                  claimed: true,
                  owners: firebase.firestore.FieldValue.arrayUnion(userDoc.data().uid)
                })
                .then(() => {
                  db.collection('Sites').doc(this.idsite.key).get()
                  .then(docSite => {
                    // edit and update fixes
                    dbErrors.collection('fixes').where('SiteID', '==', docSite.id).get()
                    .then(docPageFixCollection => {
                      dbErrors.collection('fixes_cache').where('SiteID', '==', docSite.id).limit(1).get()
                      .then(docPageFixCacheCollection => {
                        // console.log(docPageFixCollection.size,docPageFixCacheCollection.size)
                        if(docPageFixCollection.size) {
                          if(docPageFixCacheCollection.size) {
                            var arrayFixes = []
                            docPageFixCollection.forEach(docFix => {
                              if(docFix.data().changes && docFix.data().changes.length) {
                                var filterFixesOwner = docFix.data().changes.filter(item => item.user === userDoc.id)
                                if(filterFixesOwner.length) {
                                  // order by the last fix
                                  filterFixesOwner.sort((a, b) => b.idfix - a.idfix)
                                  filterFixesOwner.forEach(element => {
                                    var filterchangesFixesCache = arrayFixes.filter(ele => ele.id === element.id && ele.idError === element.idError && ele.url === element.url)
                                    if(filterchangesFixesCache.length === 0) {
                                      if(element.idError === 8 || element.idError === 14 || element.idError === 3) {
                                        arrayFixes.push({
                                          id: element.id,
                                          idError: element.idError,
                                          typeError: element.typeError,
                                          old: element.old,
                                          fix: element.fix,
                                          url: element.url,
                                          urlImg: element.urlImg,
                                          date: element.date,
                                          idfix: element.idfix
                                        })
                                      } else {
                                        arrayFixes.push({
                                          id: element.id,
                                          idError: element.idError,
                                          typeError: element.typeError,
                                          old: element.old,
                                          fix: element.fix,
                                          url: element.url,
                                          date: element.date,
                                          idfix: element.idfix
                                        })
                                      }
                                    }
                                  })
                                }
                              }
                            })

                            docPageFixCacheCollection.forEach(docFixCache => {
                              dbErrors.collection('fixes_cache').doc(docFixCache.id).update({
                                changes: arrayFixes,
                                owner: userDoc.id,
                                ownerEmail: userDoc.data().email ? userDoc.data().email : '',
                                updated: Date.now()
                              })
                              .then(() => {
                                console.log('update fixes owner')
                                // if(!this.claimLicense && arrayFixes.length >= 1) {
                                //   localStorage.setItem('configRunLicenseOwner', true)
                                // }
                              })
                              .catch((err) => console.log('err fixes owner', err.message))
                            })
                          } else {
                            dbErrors.collection('fixes_cache').add({
                              SiteID: docSite.id,
                              owner: userDoc.id,
                              ownerEmail: userDoc.data().email ? userDoc.data().email : '',
                              changes: [],
                              updated: Date.now()
                            })
                          }
                        } else {
                          if(docPageFixCacheCollection.size === 0) {
                            dbErrors.collection('fixes_cache').add({
                              SiteID: docSite.id,
                              owner: userDoc.id,
                              ownerEmail: userDoc.data().email ? userDoc.data().email : '',
                              changes: [],
                              updated: Date.now()
                            })
                          } else {
                            var arrayFixes = []
                            
                            docPageFixCacheCollection.forEach(docFixCache => {
                              dbErrors.collection('fixes_cache').doc(docFixCache.id).update({
                                changes: arrayFixes,
                                owner: userDoc.id,
                                ownerEmail: userDoc.data().email ? userDoc.data().email : '',
                                updated: Date.now()
                              })
                              .then(() => {
                                console.log('update fixes owner')
                                // if(!this.claimLicense && arrayFixes.length >= 1) {
                                //   localStorage.setItem('configRunLicenseOwner', true)
                                // }
                              })
                              .catch((err) => console.log('err fixes owner', err.message))
                            })
                          }
                        }
                      })
                    })

                    var getExternalLinks = docSite.data().externalLinks && docSite.data().externalLinks.length ? docSite.data().externalLinks : []
                    var newExternalLinks = []
                    var counterExternal = 0
                    getExternalLinks.forEach(eleExternal => {
                      counterExternal += 1
                      if(counterExternal <= 50) {
                        newExternalLinks.push(eleExternal)
                      }
                    })

                    db.collection('Sites').doc(this.idsite.key).update({
                      externalLinks: newExternalLinks,
                      owner: userData,
                      claimed: true,
                      owners: firebase.firestore.FieldValue.arrayUnion(userDoc.data().uid)
                    })

                    // if(this.claimLicense) {
                    //   localStorage.setItem('configRunLicenseOwner', true)
                    // }

                    this.sendJSONFix(docSite.id, docSite.data().homepage)

                    this.$refs.sidebar_site_ownership.hide()
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Site verified!',
                        icon: 'CoffeeIcon',
                        variant: 'success',
                      },
                    })
                    // }
                  })
                })
              })
            }
          }, 10000)
        }
      })
      .catch(e => {
        console.log(e.message)
      })
    },
    submitEditar() {
      this.claimLicense = false
      this.$refs.sidebar_site_ownership.hide()
    },
    getVerifyStatus() {
      this.itemVerify = localStorage.getItem('verify')
    },
    sendJSONFix(siteId, siteSelected) {
      // if(idc !== 7) {
        const user = auth.currentUser
        if(user) {
          db.collection('Users').doc(user.uid).get()
          .then(userDoc => {
            setTimeout(() => {
              db.collection('Sites').doc(siteId).get()
              .then(siteDoc => {
                var proPages = siteDoc.data().proPages && siteDoc.data().proPages.length ? siteDoc.data().proPages : []
                // dbErrors.collection('fixes_cache').doc(val).get()
                // .then(docFixCache => {
                dbErrors.collection('fixes_cache').where('SiteID', '==', siteId).limit(1).get()
                .then(docPageFixCacheCollection => {
                  if(docPageFixCacheCollection.size) {
                    docPageFixCacheCollection.forEach(docFixCache => {
                        var urlTransform = new URL(siteSelected)
                        var domainConverter = urlTransform.protocol + '//' + urlTransform.host

                        var getFirstUser = userDoc.id.slice(0, 4);
                        var getFirstSite = siteId.slice(0, 4);
                        var keyID = getFirstUser + getFirstSite

                        var obj = {}
                        obj.author = userDoc.data().email
                        obj.css = ""
                        obj.domain = domainConverter
                        obj.key = keyID
                        obj.mode = 1

                        var arraychanges = docFixCache.data().changes && docFixCache.data().changes.length ? docFixCache.data().changes : []
                        var pagesAlreadyProcessed = []
                        arraychanges.forEach(ele => {
                            if(pagesAlreadyProcessed.filter(item => item === ele.url).length === 0 && proPages.includes(ele.url)) {
                                var urlTransform = new URL(ele.url)
                                // var domainConverter = urlTransform.protocol + '//' + urlTransform.host

                                pagesAlreadyProcessed.push(ele.url)
                                var pathURLConverter = urlTransform.pathname === '/' && !ele.url.endsWith('/') ? ele.url + '/' : ele.url
                                var encodedURL = btoa(pathURLConverter)
                                obj[encodedURL] = {
                                    "title": "",
                                    "path": urlTransform.pathname,
                                    "url": ele.url,
                                    "description": "",
                                    "canonical": "",
                                    "siteName": "",
                                    "alt": [],
                                    "src": [],
                                    "dlinks": [],
                                    "gp": [],
                                    "tags": {},
                                    "tgstxt": {},
                                    "findAndReplace": []
                                }
                                var filterFixesOnlyEachPage = []
                                // var filterFixesOnlyEachPage = arraychanges.filter(item => item.url === ele.url)
                                var filterFixesOnlyEachPageWDL = arraychanges.filter(item => item.url === ele.url && item.idError === 9)
                                var filterFixesOnlyEachPageNDL = arraychanges.filter(item => item.url === ele.url && item.idError !== 9)
                                var condicionParaOrdenarList = (pageA, pageB) => {
                                    return pageB.idfix - pageA.idfix
                                }
                                filterFixesOnlyEachPageWDL.sort(condicionParaOrdenarList)
                                // console.log(ele.url, this.oldText, filterFixesOnlyEachPageWDL)
                                filterFixesOnlyEachPageWDL.forEach(eleErr => {
                                    if(filterFixesOnlyEachPage.filter(ele => ele.id === eleErr.id || ele.old === eleErr.old).length === 0) {
                                        filterFixesOnlyEachPage.push(eleErr)
                                    }
                                })

                                filterFixesOnlyEachPageNDL.forEach(eleErr => {
                                    filterFixesOnlyEachPage.push(eleErr)
                                })

                                // var filterFixesOnlyEachPage = arraychanges.filter(item => item.url === ele.url)
                                filterFixesOnlyEachPage.forEach(item => {
                                    if(item.idError === 1) {
                                        // title
                                        obj[encodedURL].title = item.fix
                                        // obj[encodedURL].siteName = item.fix
                                    } else if(item.idError === 2) {
                                        // desc
                                        obj[encodedURL].description = item.fix
                                    } else if(item.idError === 4) {
                                        // h1
                                        if(obj[encodedURL].tgstxt.h1 && obj[encodedURL].tgstxt.h1.length) {
                                        obj[encodedURL].tgstxt.h1.push({
                                            txt: item.old,
                                            ntx: item.fix
                                        })
                                        } else {
                                        obj[encodedURL].tgstxt.h1 = [{
                                            txt: item.old,
                                            ntx: item.fix
                                        }]
                                        }
                                    } else if(item.idError === 5) {
                                        // h2
                                        if(obj[encodedURL].tgstxt.h2 && obj[encodedURL].tgstxt.h2.length) {
                                        obj[encodedURL].tgstxt.h2.push({
                                            txt: item.old,
                                            ntx: item.fix
                                        })
                                        } else {
                                        obj[encodedURL].tgstxt.h2 = [{
                                            txt: item.old,
                                            ntx: item.fix
                                        }]
                                        }
                                    } else if(item.idError === 8) {
                                        // imgs
                                        if(obj[encodedURL].src && obj[encodedURL].src.length) {
                                        obj[encodedURL].src.push({
                                            url: item.old,
                                            src: item.fix
                                        })
                                        } else {
                                        obj[encodedURL].src = [{
                                            url: item.old,
                                            src: item.fix
                                        }]
                                        }

                                        if(item.alt) {
                                        if(obj[encodedURL].alt && obj[encodedURL].alt.length) {
                                            obj[encodedURL].alt.push({
                                            url: item.fix,
                                            alt: item.alt
                                            })
                                        } else {
                                            obj[encodedURL].alt = [{
                                            url: item.fix,
                                            alt: item.alt
                                            }]
                                        }
                                        }
                                    } else if(item.idError === 6) {
                                        // lWord
                                        // 08-03-2024 no available fix/edit (word count is value from crawl page)
                                    } else if(item.idError === 13) {
                                        // strings
                                        // if(item.findAndReplace) {
                                        if(obj[encodedURL].findAndReplace && obj[encodedURL].findAndReplace.length) {
                                            obj[encodedURL].findAndReplace.push({
                                            old: item.old,
                                            new: item.fix
                                            })
                                        } else {
                                            obj[encodedURL].findAndReplace = [{
                                            old: item.old,
                                            new: item.fix
                                            }]
                                        }
                                        // }
                                    } else if(item.idError === 3) {
                                        // mAlt
                                        // if(item.alt) {
                                        if(obj[encodedURL].alt && obj[encodedURL].alt.length) {
                                            obj[encodedURL].alt.push({
                                            url: item.urlImg,
                                            alt: item.fix
                                            })
                                        } else {
                                            obj[encodedURL].alt = [{
                                            url: item.urlImg,
                                            alt: item.fix
                                            }]
                                        }
                                        // }
                                    } else if(item.idError === 9) {
                                        // dLink
                                        // if(item.dlinks) {
                                        if(obj[encodedURL].dlinks && obj[encodedURL].dlinks.length) {
                                            obj[encodedURL].dlinks.push({
                                            url: item.old,
                                            rmp: item.fix
                                            })
                                        } else {
                                            obj[encodedURL].dlinks = [{
                                            url: item.old,
                                            rmp: item.fix
                                            }]
                                        }
                                        // }
                                    } else if(item.idError === 14) {
                                        // missimgs
                                        if(obj[encodedURL].src && obj[encodedURL].src.length) {
                                        obj[encodedURL].src.push({
                                            url: item.old,
                                            src: item.fix
                                        })
                                        } else {
                                        obj[encodedURL].src = [{
                                            url: item.old,
                                            src: item.fix
                                        }]
                                        }

                                        if(item.alt) {
                                        if(obj[encodedURL].alt && obj[encodedURL].alt.length) {
                                            obj[encodedURL].alt.push({
                                            url: item.fix,
                                            alt: item.alt
                                            })
                                        } else {
                                            obj[encodedURL].alt = [{
                                            url: item.fix,
                                            alt: item.alt
                                            }]
                                        }
                                        }
                                    } else if(item.idError === 11) {
                                        // urlCan
                                        obj[encodedURL].canonical = item.fix
                                    } else if(item.idError === 10) {
                                        // greenPadlockErrors
                                    } else if(item.idError === 15) {
                                        // Suspicious
                                        // if(item.findAndReplace) {
                                        if(obj[encodedURL].findAndReplace && obj[encodedURL].findAndReplace.length) {
                                            obj[encodedURL].findAndReplace.push({
                                            old: item.old,
                                            new: item.fix
                                            })
                                        } else {
                                            obj[encodedURL].findAndReplace = [{
                                            old: item.old,
                                            new: item.fix
                                            }]
                                        }
                                        // }
                                    }
                                })
                            }
                        })

                        proPages.forEach(ele => {
                          if(pagesAlreadyProcessed.filter(item => item === ele).length === 0) {
                            var urlTransform = new URL(ele)

                            pagesAlreadyProcessed.push(ele)
                            var pathURLConverter = urlTransform.pathname === '/' && !ele.endsWith('/') ? ele + '/' : ele
                            var encodedURL = btoa(pathURLConverter)
                            obj[encodedURL] = {
                              "title": "",
                              "path": urlTransform.pathname,
                              "url": ele,
                              "description": "",
                              "canonical": "",
                              "siteName": "",
                              "alt": [],
                              "src": [],
                              "dlinks": [],
                              "gp": [],
                              "tags": {},
                              "tgstxt": {},
                              "findAndReplace": [
                                  {
                                  "old": "seocloudtextfixer",
                                  "new": "SEOCloud text fixer"
                                  }
                              ]
                            }
                          }
                        })

                        setTimeout(() => {
                          // console.log(obj);
                          var config = {
                            method: 'POST',
                            url: 'https://apijs-wwkeahz7bq-uc.a.run.app',
                            headers: {
                              'Content-Type': 'application/json',
                            },
                            data: JSON.stringify(obj),
                          }

                          this.axios(config)
                          .then(response => {
                            dbErrors.collection('fixes_cache').doc(docFixCache.id).update({
                              api_file_request: obj
                            })
                            
                            console.log('fixes file updated: '+response.data.status)
                          })
                          .catch(e => console.log(e.message))

                          var objWP = {
                            changes: [],
                            redirects: []
                          }

                          Object.entries(obj).forEach(([key, value]) => {
                            // console.log(`Clave: ${key}, Valor:`, value.a);
                            if(key !== 'author' && key !== 'css' && key !== 'domain' && key !== 'key' && key !== 'mode') {
                              var objErrPageWP = {}
                              objErrPageWP.url = value.url
                              objErrPageWP.description = value.description
                              objErrPageWP.canonical = value.canonical
                              objErrPageWP.title = value.title
                              objErrPageWP.search_and_replace = []
                              objErrPageWP.images_src_replace = []
                              objErrPageWP.images_alt_replace = []
                              objErrPageWP.links_replace = []

                              if(value.tgstxt.h1 && value.tgstxt.h1.length) {
                                  value.tgstxt.h1.forEach(ele => {
                                  objErrPageWP.search_and_replace.push({
                                      'tag': 'h1',
                                      'target_text': ele.txt,
                                      'replace_text': ele.ntx
                                  })
                                  })
                              }

                              if(value.tgstxt.h2 && value.tgstxt.h2.length) {
                                  value.tgstxt.h2.forEach(ele => {
                                      objErrPageWP.search_and_replace.push({
                                          'tag': 'h2',
                                          'target_text': ele.txt,
                                          'replace_text': ele.ntx
                                      })
                                  })
                              }

                              if(value.src && value.src.length) {
                                  value.src.forEach(ele => {
                                      objErrPageWP.images_src_replace.push({
                                          'target_src': ele.url,
                                          'replace_src': ele.src
                                      })
                                  })
                              }

                              if(value.alt && value.alt.length) {
                                  value.alt.forEach(ele => {
                                      objErrPageWP.images_alt_replace.push({
                                          'target_src': ele.url,
                                          'replace_alt': ele.alt
                                      })
                                  })
                              }

                              if(value.dlinks && value.dlinks.length) {
                                  value.dlinks.forEach(ele => {
                                      objErrPageWP.links_replace.push({
                                          'target_url': ele.url,
                                          'replace_url': ele.rmp
                                      })
                                  })
                              }

                              if(value.findAndReplace && value.findAndReplace.length) {
                                  value.findAndReplace.forEach(ele => {
                                      objErrPageWP.search_and_replace.push({
                                          'tag': 'p',
                                          'target_text': ele.old,
                                          'replace_text': ele.new
                                      })
                                  })
                              }
                              objWP.changes.push(objErrPageWP)
                            }
                          });
                          var configWP = {
                            method: 'POST',
                            // url: `${domainConverter}/wp-admin/admin-ajax.php?action=seocloud_autosync_changes`,
                            url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/writeCrawl/json-wp',
                            headers: {
                              'Content-Type': 'application/json',
                              // 'User-Agent': 'insomnia/10.1.1',
                              // 'seocloud-registration-key': keyID
                            },
                            data: JSON.stringify({data: objWP, key: keyID, domain: domainConverter}),
                          }

                          this.axios(configWP)
                          .then(() => {
                            console.log('fixes file updated wp')
                          })
                          .catch(e => console.log(e.message))
                        }, 2000)
                    })
                  } else {
                    var getFirstUser = userDoc.id.slice(0, 4);
                    var getFirstSite = siteId.slice(0, 4);
                    var keyID = getFirstUser + getFirstSite
                    var urlTransform = new URL(siteSelected)
                    var domainConverter = urlTransform.protocol + '//' + urlTransform.host

                    var obj = {
                      "author": userDoc.data().email,
                      "css": "",
                      "domain": domainConverter,
                      "key": keyID,
                      "mode": 0
                    }
                    // console.log(this.idsite,obj)
                    proPages = [siteDoc.data().homepage]
                    var pagesAlreadyProcessed = []
                    proPages.forEach(ele => {
                      if(pagesAlreadyProcessed.filter(item => item === ele).length === 0) {
                        var urlTransform = new URL(ele)

                        pagesAlreadyProcessed.push(ele)
                        var pathURLConverter = urlTransform.pathname === '/' && !ele.endsWith('/') ? ele + '/' : ele
                        var encodedURL = btoa(pathURLConverter)
                        obj[encodedURL] = {
                          "title": "",
                          "path": urlTransform.pathname,
                          "url": ele,
                          "description": "",
                          "canonical": "",
                          "siteName": "",
                          "alt": [],
                          "src": [],
                          "dlinks": [],
                          "gp": [],
                          "tags": {},
                          "tgstxt": {},
                          "findAndReplace": [
                              {
                              "old": "seocloudtextfixer",
                              "new": "SEOCloud text fixer"
                              }
                          ]
                        }
                      }
                    })

                    var config = {
                      method: 'POST',
                      url: 'https://apijs-wwkeahz7bq-uc.a.run.app',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      data: JSON.stringify(obj),
                    }

                    this.axios(config)
                    .then(response => {
                      console.log('Create fix file: '+response.data.status)
                      // this.verifyFunction()
                    })
                    .catch(e => console.log(e.message))

                    var objWP = {
                      changes: [],
                      redirects: []
                    }

                    Object.entries(obj).forEach(([key, value]) => {
                      // console.log(`Clave: ${key}, Valor:`, value.a);
                      if(key !== 'author' && key !== 'css' && key !== 'domain' && key !== 'key' && key !== 'mode') {
                        var objErrPageWP = {}
                        objErrPageWP.url = value.url
                        objErrPageWP.description = value.description
                        objErrPageWP.canonical = value.canonical
                        objErrPageWP.title = value.title
                        objErrPageWP.search_and_replace = []
                        objErrPageWP.images_src_replace = []
                        objErrPageWP.images_alt_replace = []
                        objErrPageWP.links_replace = []

                        if(value.tgstxt.h1 && value.tgstxt.h1.length) {
                            value.tgstxt.h1.forEach(ele => {
                            objErrPageWP.search_and_replace.push({
                                'tag': 'h1',
                                'target_text': ele.txt,
                                'replace_text': ele.ntx
                            })
                            })
                        }

                        if(value.tgstxt.h2 && value.tgstxt.h2.length) {
                            value.tgstxt.h2.forEach(ele => {
                                objErrPageWP.search_and_replace.push({
                                    'tag': 'h2',
                                    'target_text': ele.txt,
                                    'replace_text': ele.ntx
                                })
                            })
                        }

                        if(value.src && value.src.length) {
                            value.src.forEach(ele => {
                                objErrPageWP.images_src_replace.push({
                                    'target_src': ele.url,
                                    'replace_src': ele.src
                                })
                            })
                        }

                        if(value.alt && value.alt.length) {
                            value.alt.forEach(ele => {
                                objErrPageWP.images_alt_replace.push({
                                    'target_src': ele.url,
                                    'replace_alt': ele.alt
                                })
                            })
                        }

                        if(value.dlinks && value.dlinks.length) {
                            value.dlinks.forEach(ele => {
                                objErrPageWP.links_replace.push({
                                    'target_url': ele.url,
                                    'replace_url': ele.rmp
                                })
                            })
                        }

                        if(value.findAndReplace && value.findAndReplace.length) {
                            value.findAndReplace.forEach(ele => {
                                objErrPageWP.search_and_replace.push({
                                    'tag': 'p',
                                    'target_text': ele.old,
                                    'replace_text': ele.new
                                })
                            })
                        }
                        objWP.changes.push(objErrPageWP)
                      }
                    });
                    var configWP = {
                      method: 'POST',
                      // url: `${domainConverter}/wp-admin/admin-ajax.php?action=seocloud_autosync_changes`,
                      url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/writeCrawl/json-wp',
                      headers: {
                        'Content-Type': 'application/json',
                        // 'User-Agent': 'insomnia/10.1.1',
                        // 'seocloud-registration-key': keyID
                      },
                      data: JSON.stringify({data: objWP, key: keyID, domain: domainConverter}),
                    }

                    this.axios(configWP)
                    .then(() => {
                      console.log('fixes file updated wp')
                    })
                    .catch(e => console.log(e.message))
                  }
                })
              })
            }, 6300)
          })
        }
      // }
    }
  },
  mounted() {
    this.claimLicense = false
    this.events.forEach((event) => {
      this.itemVerify = window.addEventListener(event, this.getVerifyStatus)
    })
  },
  destroyed() {
    this.events.forEach((event) => {
        window.removeEventListener(event, this.getVerifyStatus)
    })
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-right-site-ownership {
    width: 90%;
    max-width: 593px !important;
}
.link_claim:hover {
  color: #a7aab9 !important;
}
// @media (min-width: 769px) {
// }
@media (max-width: 768px) {
  .container-body-config-claim {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
// label {
//   margin-bottom: 0px !important;
//   height: 25px;
// }
// .col-form-label {
//   margin-bottom: 0px !important;
//   height: 25px;
//   font-size: 0.857rem !important;
// }
// .form-control {
//   height: 35px;
// }
// .v-select {
//   height: 35px;
//   margin-top: 0px;
// }
// .vs__dropdown-toggle {
//   height: 35px;
// }
// .vs__selected {
//   margin-top: 0px !important;
// }
</style>
